<template>
    <div class="community-activity-shop-logs">
        <ATable :fields="fields" :data="tableData">
            <template #col-date="{ data }">
                <CollectionCommunityActivityDate :date="data" />
            </template>
            <template #col-wallet="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-traits="{ data }">
                <div class="community-activity-traits">
                    <CollectionCommunityActivityTrait
                        v-for="(item, idx) in data"
                        :trait="item"
                        :key="idx"
                    />
                </div>
            </template>
        </ATable>
    </div>
</template>
<script setup>
import ATable from "@/components/ATable/ATable.vue";
import { shorten } from "@/helpers/utils";
import { computed, ref } from "vue";
import CollectionCommunityActivityDate from "./CollectionCommunityActivityDate.vue";
import CollectionCommunityActivityTrait from "./CollectionCommunityActivityTrait.vue";
import CollectionCommunityActivityWallet from "./CollectionCommunityActivityWallet.vue";
const props = defineProps({ items: { type: Array, default: () => [] } })

const fields = [
    {
        name: "Date",
        value: "date"
    },
    {
        name: "Wallet",
        value: "wallet"
    },
    {
        name: "Traits",
        value: "traits"
    },
]
const tableData = computed(() => props.items)
</script>
<style lang="scss"></style>