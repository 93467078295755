<template>
    <div class="community-activity-meth">
        <ATable :fields="fields" :data="items">
            <template #col-meth_amount="{ data }">{{ data }} METH</template>
            <template
                #col-reward_type_pretty="{ data, index }"
            >{{ data }} {{ items[index].recipient ? `To: ${shorten(items[index].recipient, 4, 4)}` : '' }}</template>
            <template #col-wallet_address="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-date_granted="{ data }">
                <CollectionCommunityActivityDate :date="data" />
            </template>
        </ATable>
    </div>
</template>
<script setup>
import ATable from "@/components/ATable/ATable.vue";
import { shorten } from "@/helpers/utils";
import { ref } from "vue";
import CollectionCommunityActivityDate from "./CollectionCommunityActivityDate.vue";
import CollectionCommunityActivityWallet from "./CollectionCommunityActivityWallet.vue";

const props = defineProps({ items: { type: Array, default: () => [] } })
const fields = [
    {
        name: "Date",
        value: "date_granted"
    },
    {
        name: "Wallet",
        value: "wallet_address"
    },
    {
        name: "Type",
        value: "reward_type_pretty"
    },
    {
        name: "Amount",
        value: "meth_amount"
    },
]
</script>
<style lang="scss"></style>