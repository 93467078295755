<template>
    <div class="community-activity-tabs">
        <a
            class="community-activity-tabs__item"
            v-for="(tab, idx) in tabs"
            :key="idx"
            href="javascript:void(0)"
            v-tooltip="{ content: tab.disabled || (tab.name === 'All' && !myActivity) ? 'You need an Ape ranked in the top 5% to access full data' : '', distance: 10 }"
            @click.prevent="selectTab(tab.value, tab.disabled)"
            :class="{ active: props.tab === tab.value, disabled: tab.disabled }"
        >
            {{ tab.name }}
            <IInfo v-if="tab.name === 'All' && !myActivity" :width="10" />
        </a>
    </div>
</template>
<script setup>
import { CommunityActivityTabs } from "./community-activity-tabs"
import IInfo from "@/assets/img/info.svg?inline"
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed } from "vue-demi";

const props = defineProps({ tab: String, myActivity: Boolean })
const emit = defineEmits(['update:tab'])
const hasApes = computed(() => {
    return useWalletCollectionStore().allApes.some(ape => ape.rank <= 500)
})
const tabs = computed(() => {
    return [
        {
            name: "All",
            value: CommunityActivityTabs.ALL,
            disabled: props.myActivity
        },
        {
            name: "METH Transaction",
            value: CommunityActivityTabs.METH_TRANSACTIONS,
            disabled: !hasApes.value && !props.myActivity
        },
        {
            name: "Trading Logs",
            value: CommunityActivityTabs.TRADING_LOGS,
            disabled: !hasApes.value && !props.myActivity
        },
        {
            name: "Shop Logs",
            value: CommunityActivityTabs.SHOP_LOGS,
            disabled: !hasApes.value && !props.myActivity
        },

        {
            name: "Formulas Log",
            value: CommunityActivityTabs.FORMULAS_LOGS,
            disabled: !hasApes.value && !props.myActivity
        },
        {
            name: "Loot Box Spin",
            value: CommunityActivityTabs.LOOTBOX_SPIN,
            disabled: !hasApes.value && !props.myActivity
        },
        {
            name: "Traits Transfers",
            value: CommunityActivityTabs.TRAITS_LOGS,
            disabled: !hasApes.value && !props.myActivity
        },
    ]

})
const selectTab = (tab, disabled) => {
    if (!disabled) {
        emit('update:tab', tab)
    }
}

</script>
<style lang="scss">
.community-activity-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    &__item {
        padding: 5px 10px;
        border-top: 1px solid var(--border);
        border-bottom: 1px solid var(--border);
        border-right: 1px solid var(--border);
        color: #fff;
        text-decoration: none;
        &.disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
        &.active {
            background-color: var(--primary);
        }
        &:first-child {
            border-left: 1px solid var(--border);
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;

        &__item {
            margin: 4px;
            border: 1px solid var(--border) !important;
            border-radius: 5px;
        }
    }
}
</style>