<template>
    <div class="community-activity-stats">
        <div class="community-activity-stats-title"></div>
        <ASelect :items="timeOptions" v-model:value="activeTime" class="community-activity-stats-select">
            <template #icon>&nbsp; </template>
        </ASelect>
        <div class="community-activity-stats-items" v-if="!isLoading">
            <div class="community-activity-stats__item" v-for="(item, idx) in itemsData" :key="idx">
                <div class="community-activity-stats__item-title">{{ item.title }}</div>
                <div class="community-activity-stats__item-value">{{ item.value }} </div>
            </div>
        </div>
        <div class="community-activity-stats-items" v-if="!isLoading">
            <div class="community-activity-stats__item" v-for="(item, idx) in burnedItemsData" :key="idx">
                <div class="community-activity-stats__item-title">{{ item.title }}</div>
                <div class="community-activity-stats__item-value">{{ item.value }} </div>
            </div>
        </div>
        <div class="community-activity-stats-loading" v-if="isLoading">
            <img class="loader" src="@/assets/img/spinner-clear.svg" width="50" />
        </div>
    </div>
</template>
<script setup>
import { useBapApi } from '@/api/useBapApi';
import ASelect from '@/components/ASelect.vue';
import { formatNumberSpaces } from '@/helpers/format-number-spaces';
import * as moment from "moment"
import { computed, ref, watch } from 'vue';
const stats = ref({})
const timeOptions = [
    // {
    //     name: 'Last 24h',
    //     value: 1
    // },
    {
        name: 'Last 7 days',
        value: 7
    },
    {
        name: 'Last 30 days',
        value: 30
    },
    {
        name: 'All',
        value: 1000
    },
]
const activeTime = ref(7)
const isLoading = ref(false)
const fetchData = async () => {
    isLoading.value = true
    const res = await useBapApi({ toast: { error: true } }).exec({
        url: '/info/stats', params: {
            to: moment().unix() * 1000,
            from: moment().subtract(activeTime.value, 'days').unix() * 1000
        }
    })
    stats.value = {
        methTransfered: res.transactions.find(t => t.type === 'meth_transfer')?.total,
        traitShopPayment: res.transactions.find(t => t.type === 'trait_shop_payment')?.total,
        forgePayment: res.transactions.find(t => t.type === 'forge_payment')?.total,
        tradingPayment: res.transactions.find(t => t.type === 'trading_payment')?.total,
        burnedTraits: res.assetsBurned.find(t => t.type === 'traits')?.total,
        burnedUtilities: res.assetsBurned.find(t => t.type === 'utility')?.total,
        traitsTraded: res.traitsTraded
    }
    isLoading.value = false
}
watch(activeTime, () => {
    fetchData()
})
const itemsData = computed(() => {
    return [
        {
            title: "METH Transfered",
            value: formatNumberSpaces(stats.value.methTransfered || 0) + ' METH'
        },
        {
            title: "The Shop Volume",
            value: formatNumberSpaces(stats.value.traitShopPayment || 0) + ' METH'
        },
        {
            title: "Formulas Volume",
            value: formatNumberSpaces(stats.value.forgePayment || 0) + ' METH'
        },
        {
            title: "Traits Trading Volume",
            value: formatNumberSpaces(stats.value.tradingPayment || 0) + ' METH'
        },


    ]
})
const burnedItemsData = computed(() => {
    return [
        {
            title: "Burned Traits",
            value: formatNumberSpaces(stats.value.burnedTraits || 0)
        },
        {
            title: "Burned Utilities",
            value: formatNumberSpaces(stats.value.burnedUtilities || 0)
        },
        {
            title: "Traits Traded",
            value: formatNumberSpaces(stats.value.traitsTraded || 0)
        },
    ]
})
fetchData()
</script>
<style lang="scss">
.community-activity-stats {
    max-width: 725px;
    margin: 0 auto;
    padding: 0 10px;
    margin-bottom: 40px;

    &-title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    &-loading {
        text-align: center;
    }

    &-items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &-select {
        width: 140px !important;
        margin-bottom: 5px;

        .app-select-header {
            padding: 5px;
            background-color: transparent;
        }
    }

    &__item {
        text-align: center;
        padding: 8px;
        border-radius: 5px;
        background-color: var(--gray-light);
        box-sizing: border-box;
        margin: 5px;

        &-title {
            text-transform: uppercase;
            opacity: 0.5;
            font-weight: bold;
            margin-bottom: 5px;
        }

        &-value {}
    }
}
</style>