<template>
    <div class="community-activity-trading-log">
        <ATable :fields="fields" :data="tableData">
            <template #col-date="{ data }">
                <CollectionCommunityActivityDate :date="data" />
            </template>
            <template #col-buyer="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-seller="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-trait="{ data }">
                <CollectionCommunityActivityTrait :trait="data" />
            </template>
        </ATable>
    </div>
</template>
<script setup>
import ATable from "@/components/ATable/ATable.vue";
import { shorten } from "@/helpers/utils";
import { computed, ref } from "vue";
import CollectionCommunityActivityTrait from "./CollectionCommunityActivityTrait.vue";
import CollectionCommunityActivityWallet from "./CollectionCommunityActivityWallet.vue";
import CollectionCommunityActivityDate from "./CollectionCommunityActivityDate.vue";
const props = defineProps({ items: { type: Array, default: () => [] } })

const fields = [
    {
        name: "Date",
        value: "date"
    },
    {
        name: "Amount",
        value: "amount"
    },
    {
        name: "Buyer",
        value: "buyer"
    },
    {
        name: "Seller",
        value: "seller"
    },
    {
        name: "Trait",
        value: "trait"
    },
]
const tableData = computed(() => props.items)
</script>
<style lang="scss"></style>