<template>
    <div class="community-activity-lootbox">
        <ATable :fields="fields" :data="items">
            <template #col-date="{ data }">
                <CollectionCommunityActivityDate :date="data" />
            </template>
            <template #col-sender="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-prizes="{ data }">
                <div class="community-activity-traits">
                    <CollectionCommunityActivityTrait
                        v-for="(item, idx) in data"
                        :key="idx"
                        :trait="item"
                    />
                </div>
            </template>
        </ATable>
    </div>
</template>
<script setup>
import ATable from "@/components/ATable/ATable.vue";
import { computed, ref } from "vue";
import CollectionCommunityActivityDate from "./CollectionCommunityActivityDate.vue";
import CollectionCommunityActivityWallet from "./CollectionCommunityActivityWallet.vue";
import CollectionCommunityActivityTrait from "./CollectionCommunityActivityTrait.vue";
const props = defineProps({ items: { type: Object, default: () => ({}) } })
const activeType = ref('common')
const items = computed(() => {
    return Object.keys(props.items).reduce((arr, boxType) => {
        return arr.concat(props.items[boxType])
    }, [])
})
const fields = [
    {
        name: "Date",
        value: "date"
    },
    {
        name: "Wallet",
        value: "sender"
    },
    {
        name: "Type",
        value: "boxType"
    },
    {
        name: "Prizes",
        value: "prizes"
    },
]
</script>
<style lang="scss"></style>