<template>
    <div class="community-activity-trading-log">
        <ATable :fields="fields" :data="tableData">
            <template #col-date="{ data }">
                <CollectionCommunityActivityDate :date="data" />
            </template>
            <template #col-sender="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-recipient="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-traits="{ data }">
                <div class="community-activity-traits">
                    <CollectionCommunityActivityTrait
                        v-for="(item, idx) in data"
                        :trait="item"
                        :key="idx"
                    />
                </div>
            </template>
        </ATable>
    </div>
</template>
<script setup>
import ATable from "@/components/ATable/ATable.vue";
import utilitiesMetadata from "@/config/utilities-metadata"
import { shorten } from "@/helpers/utils";
import { computed, ref } from "vue";
import CollectionCommunityActivityTrait from "./CollectionCommunityActivityTrait.vue";
import CollectionCommunityActivityWallet from "./CollectionCommunityActivityWallet.vue";
import CollectionCommunityActivityDate from "./CollectionCommunityActivityDate.vue";
import { isDate } from "moment";
const props = defineProps({ items: { type: Array, default: () => [] } })

const fields = [
    {
        name: "Date",
        value: "date"
    },
    {
        name: "Sender",
        value: "sender"
    },
    {
        name: "Recipient",
        value: "recipient"
    },
    {
        name: "Traits",
        value: "traits"
    },
]
const tableData = computed(() => {
    return props.items.map(item => {
        return {
            ...item,            
            traits: item.ids.map((trait, i) => {
                const idx = item.ids.findIndex(t => t.id === trait.id)
                if (idx !== i) return null

                let amount = item.ids.filter(t => t.id === trait.id).length

                if(!(amount > 1)){
                    amount = item.amounts[i]
                }

                if(trait.type === "utility"){
                   return {
                       ...utilitiesMetadata[trait.id],
                       name: `${utilitiesMetadata[trait.id].name} x${amount}`
                   }
                }         

                return {
                   ...trait,
                    name: `${trait.name} x${amount}`,
                }
            })
            .filter(t => t)
        }
    })
})
</script>
<style lang="scss"></style>