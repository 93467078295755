<template>
    <div class="app-table-wrapper">
        <table class="app-table">
            <thead>
                <tr class="app-table-header">
                    <td
                        v-for="item in fields"
                        :key="item.name"
                        class="app-table-col"
                    >{{ item.name }}</td>
                </tr>
            </thead>
            <tr
                v-for="(dataItem, idx) in data"
                :key="idx"
                class="app-table-row"
                :class="rowClass && rowClass(dataItem, idx)"
            >
                <td v-for="headerItem in fields" :key="headerItem.value" class="app-table-col">
                    <slot
                        :name="'col-' + headerItem.value"
                        :data="dataItem[headerItem.value]"
                        :index="idx"
                    >{{ dataItem[headerItem.value] }}</slot>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>

export default {
    // components: { APagination, ISort, ITriangle },
    props: {
        fields: {
            type: Array,
            default: () => ([])
        },
        data: {
            type: Array,
            default: () => ([])
        },
        rowClass: Function,
    },
    data: () => ({
        page: 1,
        pageSize: 15,
    }),
    computed: {
        tableHeader() {
            return this.fields
        },


    },
    methods: {

    }
}
</script>
  