<template>
    <div class="community-activity-formulas">
        <ATable :fields="fields" :data="items">
            <template #col-date="{ data }">
                <CollectionCommunityActivityDate :date="data" />
            </template>
            <template #col-wallet="{ data }">
                <CollectionCommunityActivityWallet :wallet="data" />
            </template>
            <template #col-traitOut="{ data }">
                <CollectionCommunityActivityTrait :trait="data" />
            </template>
           
        </ATable>
    </div>
</template>
<script setup>
import ATable from "@/components/ATable/ATable.vue";
import { ref } from "vue";
import CollectionCommunityActivityDate from "./CollectionCommunityActivityDate.vue";
import CollectionCommunityActivityWallet from "./CollectionCommunityActivityWallet.vue";
import CollectionCommunityActivityTrait from "./CollectionCommunityActivityTrait.vue";
const props = defineProps({ items: { type: Array, default: () => [] } })
const fields = [
    {
        name: "Date",
        value: "date"
    },
    {
        name: "Wallet",
        value: "wallet"
    },
    {
        name: "Trait",
        value: "traitOut"
    },
]
</script>
<style lang="scss"></style>