<template>
  <div class="app-switcher-wrapper">
    <div
      class="app-switcher"
      :class="{ active: value }"
      @click.prevent="toggle"
    >
      <span />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    toggle() {
      this.$emit("update:value", !this.value);
    },
  },
};
</script>
