<template>
    <div class="community-activity">
        <CommunityActivityTabsC v-model:tab="activeTab" :myActivity="myActivity" />
        <div class="community-activity-my-wallet">
            <div class="community-activity-my-wallet-title">Show My activity</div>
            <ASwitcher v-model:value="myActivity" />
        </div>
        <div class="community-activity-date" v-if="activeTab !== CommunityActivityTabs.ALL">
            <div class="community-activity-date-title">Select Date</div>

            <Datepicker
                class="community-activity-date-picker"
                v-model="date"
                range
                :dark="true"
                :enable-time-picker="false"
                auto-apply
                :clearable="true"
                :disabled-dates="disabledDates"
                @update:model-value="onDateChange"
            />
        </div>
        <div class="community-activity-loading" v-if="isLoading">
            <img v-if="isLoading" class="loader" src="@/assets/img/spinner-clear.svg" width="80" />
        </div>

        <div class="community-activity-content" v-else>
            <CollectionCommunityActivityMeth
                v-if="activeTab === CommunityActivityTabs.METH_TRANSACTIONS"
                :items="items"
            />
            <CollectionCommunityActivityTradingLog
                v-if="activeTab === CommunityActivityTabs.TRADING_LOGS"
                :items="items"
            />
            <CollectionCommunityActivityShopLogs
                v-if="activeTab === CommunityActivityTabs.SHOP_LOGS"
                :items="items"
            />
            <CollectionCommunityActivityFormulasLogs
                v-if="activeTab === CommunityActivityTabs.FORMULAS_LOGS"
                :items="items"
            />
            <CollectionCommunityActivityLootBox
                v-if="activeTab === CommunityActivityTabs.LOOTBOX_SPIN"
                :items="items"
            />
            <CollectionCommunityActivityTraitTransfer
                v-if="activeTab === CommunityActivityTabs.TRAITS_LOGS"
                :items="items"
            />
            <CollectionCommunityActivityAll
                v-if="activeTab === CommunityActivityTabs.ALL"
                :items="items"
            />
            <APagination
                v-if="activeTab !== CommunityActivityTabs.ALL"
                class="community-activity-pagination"
                v-model:page="activePage"
                :totalItems="totalItems"
                :pageSize="pageSize"
            />
        </div>
    </div>
</template>
<script setup>
import { useAnyApi } from "@/api/useAnyApi";
import { Config } from "@/config";
import { ref, watch } from "vue-demi";
import CommunityActivityTabsC from "./CommunityActivityTabs.vue";
import CollectionCommunityActivityMeth from "./CollectionCommunityActivityMeth.vue";
import { CommunityActivityTabs } from "./community-activity-tabs";
import APagination from "@/components/APagination.vue";
import CollectionCommunityActivityTradingLog from "./CollectionCommunityActivityTradingLog.vue";
import CollectionCommunityActivityShopLogs from "./CollectionCommunityActivityShopLogs.vue";
import CollectionCommunityActivityFormulasLogs from "./CollectionCommunityActivityFormulasLogs.vue";
import CollectionCommunityActivityLootBox from "./CollectionCommunityActivityLootBox.vue";
import CollectionCommunityActivityTraitTransfer from "./CollectionCommunityActivityTraitTransfer.vue";
import moment from "moment";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useBapApi } from "@/api/useBapApi";
import CollectionCommunityActivityAll from "./CollectionCommunityActivityAll.vue";
import { useWeb3Store } from "@/store/web3";
import ACheckbox from "@/components/ACheckbox.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import ASwitcher from "@/components/ASwitcher.vue";

const activePage = ref(1)
const myActivity = ref(false)

const date = ref(null)
const activeWallet = ref()

const activeTab = ref(CommunityActivityTabs.ALL)
const pageSize = ref(20)
const totalItems = ref(0)
const items = ref([])
const isLoading = ref(false)
const fetchData = async () => {
    const urls = {
        [CommunityActivityTabs.ALL]: 'all-logs',
        [CommunityActivityTabs.LOOTBOX_SPIN]: 'lootbox-spins',
        [CommunityActivityTabs.TRADING_LOGS]: 'traits-trading-logs',
        [CommunityActivityTabs.SHOP_LOGS]: 'shop-logs',
        [CommunityActivityTabs.METH_TRANSACTIONS]: 'meth-transactions-mainnet',
        [CommunityActivityTabs.TRAITS_LOGS]: 'traits-transfer-logs',
        [CommunityActivityTabs.FORMULAS_LOGS]: 'formulas-log',
    }
    isLoading.value = true
    const currentTab = activeTab.value

    // const res = await useBapApi({ toast: { error: true } }).exec({
    // url: '/info/' + urls[activeTab.value],
    const res = await useAnyApi({ toast: { error: true } }).exec({
        baseURL: "https://brrod86tu2.execute-api.us-east-1.amazonaws.com/info/",
        url: urls[activeTab.value],
        params: {
            page: activePage.value,
            from: date.value ? date.value[0]?.getTime() : undefined,
            to: date.value ? date.value[1]?.getTime() : undefined,
            wallet: myActivity.value ? useWeb3Store().collectionWallet : undefined,
            network: 'mainnet'
        }
    })
    isLoading.value = false

    if (!res || currentTab !== activeTab.value) return
    if (activeTab.value === CommunityActivityTabs.ALL) {
        items.value = res
        return
    }
    totalItems.value = res.total
    pageSize.value = res.pageSize
    items.value = res.list

}
fetchData()

watch(activeTab, () => {
    fetchData()
    activePage.value = 1
})
watch(activePage, () => {
    fetchData()
})

const disabledDates = (date) => {
    return moment().diff(moment(date)) < 0
}
const onDateChange = () => {
    activePage.value = 1
    fetchData(true)
}
watch(myActivity, () => {
    if (myActivity.value && activeTab.value === CommunityActivityTabs.ALL) {
        activeTab.value = CommunityActivityTabs.METH_TRANSACTIONS
    }

    if (!myActivity.value && activeTab.value !== CommunityActivityTabs.ALL && !useWalletCollectionStore().allApes.some(ape => ape.rank <= 500)) {
        activeTab.value = CommunityActivityTabs.ALL
    }
})
</script>
<style lang="scss">
.community-activity {
    &-pagination {
        justify-content: center;
        margin-top: 30px;
    }
    &-content {
        margin-top: 20px;
    }
    &-loading {
        text-align: center;
        padding: 40px 0;
    }
    &-date {
        margin: 0 15px;
        margin-top: 20px;
        margin-bottom: 20px;
        &-picker {
            width: 300px;
        }
        &-title {
            margin-bottom: 10px;
            text-align: left;
            text-transform: uppercase;
            opacity: 0.5;
            font-weight: bold;
        }
    }
    &-traits {
        > a {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-my-wallet {
        padding: 10px;
        border-radius: 5px;
        display: inline-flex;
        background-color: var(--gray-light);
        box-sizing: border-box;
        margin: 0 15px;
        margin-top: 15px;
        &-title {
            text-transform: uppercase;
            margin-right: 5px;
            font-weight: bold;
        }
    }
}
</style>