<template>
    <div class="page-community-activity">
        <div class="button-back-wrapper">
            <div class="page-community-activity-actions-back button-back" @click.prevent="goBack">
                <IArrowLeft width="32" />
                <span>Back</span>
            </div>
        </div>
        <h2>Community Activity</h2>
        <CollectionCommunityActivityStats />

        <CollectionCommunityActivity />
    </div>
</template>
<script setup>
import CollectionCommunityActivity from "@/components/Collection/CollectionCommunityActivity/CollectionCommunityActivity.vue";
import IArrowLeft from "@/assets/icons/arrow-left-long.svg?inline"
import { useRouter } from "vue-router";
import CollectionCommunityActivityStats from "@/components/Collection/CollectionCommunityActivity/CollectionCommunityActivityStats.vue";
const router = useRouter()
const goBack = () => {
    const lastPath = router.options.history.state.back
    if (lastPath) {
        router.back()
    } else {
        router.push({ name: "Collection" })
    }
}
</script>
<style lang="scss">
.page-community-activity {
    max-width: 1350px;
    margin: 0 auto;
    width: 100%;
    h2 {
        text-align: center;
    }
    .button-back-wrapper {
        max-width: 100%;
        margin-top: 40px;
    }
}
</style>