<template>
  <a
    href="javascript:void(0)"
    class="app-checkbox"
    :class="{ active }"
    @click.prevent="$emit('update:active', !active)"
  >
    <ICheckmark
      width="24"
      class="app-checkbox-mark"
    />
  </a>
</template>
<script>
import ICheckmark from "../assets/icons/checkmark.svg?inline";
export default {
  components: { ICheckmark },
  props: {
    active: Boolean,
  },
  emits: ["update:active"],
};
</script>
